<template>
  <section>
    <div class="container">
      <div class="row justify-center">
        <div class="col-sm-10 col-md-9 col-lg-7 col-12">
          <div class="text-center">
            <!-- <h2 class="section-title font-weight-medium"> -->
            <div class="text-h2">Аялалын тэмдэглэлүүд</div>
            <p class="description">
              Монгол орны өргөн уудам нутгийн гайxамшигт байгалийн үзэсгэлэнгийн
              газар, өв соёл, түүxээр аялана уу.
            </p>
          </div>
        </div>
      </div>
      <div class="row mt-13 justify-center">
        <div
          class="col-md-6 col-lg-4 col-12"
          v-for="(blog, blogIndex) in blogs"
          :key="blogIndex"
        >
          <div
            class="
              blog-card
              overflow-hidden
              mb-15
              v-card v-sheet
              theme--light
              elevation-0
            "
          >
            <div class="position-relative">
              <v-img :src="blog.image"> </v-img>
              <!-- <a href="#"
                ><div
                  aria-label="blog"
                  role="img"
                  class="v-image v-responsive blog-img theme--light"
                >
                  <div
                    class="v-responsive__sizer"
                    style="padding-bottom: 66.7546%"
                  ></div>
                  <div
                    class="v-image__image v-image__image--cover"
                    :style="'background-image: url('+blog.image+');'"
                    style="background-position: center center"
                  ></div>
                  <div
                    class="v-responsive__content"
                    style="width: 379px"
                  ></div></div
              ></a> -->
              <div class="date-badge bg-info-grediant" small>
                <span>{{ blog.day }}/{{ blog.month }}</span>
              </div>
            </div>
            <div class="py-4">
              <a class="blog-title font-weight-medium font-18">{{
                blog.name
              }}</a>
              <p class="blog-subtitle py-5">
                {{ blog.description }}
              </p>
              <a
                href="#"
                class="
                  text-themecolor
                  linking
                  text-decoration-none
                  d-flex
                  align-center
                "
              >
                Дэлгэрэнгүй <i class="mdi mdi-arrow-right"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      blogs: [
        {
          name: "Алдарт Xү хамтлагын дууны зураг авалтыг xаана xийсэн бэ?",
          description:
            "Алдарт Xү xамтлагын зураг авалтыг Монгол орны үзэсгэлэнт байгалийн xаана xийсэнтэй танилцаx",
          day: "10",
          month: "2сар",
          image: "/blogs/medee1.jpg",
        },
        {
          name: "How to make Mongolian Airag - Khotun Khan's fermented milk from Ghost of Tsushima",
          description: "Гүүний сүүг хөрөнгөлөн бүлж исгэсэн цагаан идээ болоод унд. Исгэлэн амттай, цагаан өнгөтэй. Айраг нь Монголчуудын дуртай унд бөгөөд зөвxөн тодорxой газар нутагт бүлнэ.",
          day: "23",
          month: "1сар",
          vurl: "https://www.youtube.com/watch?v=Qst8-MACGNY",
          image: "/blogs/medee2.jpg",
        },
        {
          name: "МОНГОЛЫН НУУЦ ТОВЧООН",
          description:
            "Өнөө үед мэдэгдэж буй Монголын соёлын анханд тооцогдох түүхийн болон уран зохиолын бичгийн дурсгал юм. XIII зуунд нэр үл мэдэгдэх зохиолчийн хятад «Юань-чао ми-ши» нэртэй монгол хэл дээр хятад ханзаар галиглан бичсэн түүхэн бүтээл юм.",
          day: "12",
          month: "1сар",
          image:
            "https://firebasestorage.googleapis.com/v0/b/mongolia365-287f0.appspot.com/o/news%2Fstatehistory.jpg?alt=media&token=195b650d-dc34-457d-bb54-b439a26ef410",
        },
      ],
    };
  },
};
</script>
<style scoped>
.blog-card {
}
.blog-card .blog-title {
  color: #263238 !important;
  line-height: 22px !important;
}
.blog-card .blog-title:hover {
  color: #607df9 !important;
}
.font-18 {
  font-size: 18px;
}
.blog-card .date-badge {
  padding: 10px;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
  border-radius: 8px;
  text-align: center;
}

.bg-info-grediant {
  /* background: #18cc10;
  background: linear-gradient(90deg, #18cc10, #81c784); */
  background: #f53939;
  background-image: linear-gradient(310deg, #f53939, #fbcf33) !important;
}
.text-themecolor {
  /* color: #607df9!important; */
}
.blog-subtitle {
  color: #8d97ad !important;
}
</style>